//=================================================
// Global bundle
//=================================================
import '@hotwired/turbo-rails';
// Variable for conditionally firing GA events
window.useAnalytics = document.location.hostname === 'whitney.org';
// Transition duration timings (should match css variables)
window.transitionDurationSlow = 400;
window.transitionDuration = 200;
window.transitionDurationFast = 100;
// Helpful global var for knowing if the main nav has collapsed (should match css media queries)
// requires the window resize listener in application.js to accurate if viewport size changes
window.isNavSmall = window.innerWidth < 800;
window.isSmall = window.innerWidth < 650;
// Variable for tracking previous window width
window.cacheWidth = window.innerWidth;
